import React from 'react';
import { PRIMARY_BUTTON_COLOR } from '../../styles/colors';

const Loading = () => (
  <svg style={ { marginTop: "25px" } } width={35} height={35} viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke={PRIMARY_BUTTON_COLOR}>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)" strokeWidth="2">
        <circle strokeOpacity=".3" cx="18" cy="18" r="18" />
        <path stroke={PRIMARY_BUTTON_COLOR} d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </g>
  </svg>
);

export default Loading;
