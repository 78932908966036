import queryString from "query-string";
import React, { useState } from "react";
import styled from "styled-components";
import { SNOW, PRIMARY_BUTTON_COLOR, WEAK_FONT_COLOR } from "../../styles/colors";
import StaccIcon from "../../icons/StaccIcon";
import Loading from "./Loading";
import axios from "axios";

enum LoginState {
  Email = "email",
  Nonce = "nonce"
}

const getAuthority = (hostname: string): string => {
  if (hostname === "localhost") return "https://oidc.flow.stacc.dev";
  const authority = hostname.split(".");
  authority.splice(0, 1, "oidc");
  return `https://${authority.join(".")}`;
};

async function handleSubmit(
  e: React.FormEvent<HTMLFormElement>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
  email: string,
  nonce: string,
  loginState: string,
  setLoginState: React.Dispatch<React.SetStateAction<LoginState>>
) {
  e.preventDefault();
  setLoading(true);
  try {
    const { state } = queryString.parse(window.location.search);
    if (loginState === LoginState.Email) {
      await axios.post("/api/request-link", { email, state });
      setLoginState(LoginState.Nonce);
      setLoading(false);
    } else if (loginState === LoginState.Nonce) {
      await axios.post("/api/magic-link", { state, nonce });
      const oidcUrl = getAuthority(window.location.hostname);
      window.location.replace(oidcUrl + "/callback/email-otp?" + queryString.stringify({ state }));
    }

    setTimeout(() => setLoading(false), 10000);
  } catch (err) {
    console.log("err");
    console.log(err.response);
    setTimeout(() => setLoading(false), 1500);
    if (err.response && err.response.status === 401) return setTimeout(() => setError("Could not authorize the user"), 1500);
    if (err.response && err.response.status === 429) return setTimeout(() => setError("Too many attempts! Please try a new login attempt."), 1500);
    if (err.response && err.response.status === 403) return setTimeout(() => setError("Failed to complete login attempt. Please try again."), 1500);
    if (err.response && err.response.status === 500) return setTimeout(() => setError("Failed attempt, please re-initiate login."), 1500);
    return setTimeout(() => setError(err.message), 1500);
  }
}

const App: React.FC = () => {
  const [email, setEmail] = useState("");
  const [nonce, setNonce] = useState("");
  const [loginState, setLoginState] = useState(LoginState.Email);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const isSubmitDisabled = () => {
    if (loginState === LoginState.Email) return email === "";
    else if (loginState === LoginState.Nonce) return nonce === "";
    else return true;
  };

  return (
    <Card onSubmit={e => handleSubmit(e, setLoading, setErrorMsg, email, nonce, loginState, setLoginState)}>
      <Circle>
        <StaccIcon />
      </Circle>
      <div style={{ height: 20, marginBottom: 10 }}>{errorMsg ? <P style={{ color: "red", fontSize: 12 }}>{errorMsg}</P> : ""}</div>
      <div style={{ width: "50%" }}>
        <P>E-mail</P>
      </div>
      <InputField disabled={loginState === LoginState.Nonce} value={email} onChange={e => setEmail(e.target.value)} type="text" />
      {loginState === LoginState.Nonce ? (
        <div style={{ width: "50%" }}>
          <P>OTP</P>
        </div>
      ) : null}
      {loginState === LoginState.Nonce ? <InputField value={nonce} onChange={e => setNonce(e.target.value)} type="text" /> : null}
      <div style={{ height: 100 }}>
        {loading ? (
          <Loading />
        ) : (
          <Button isDisabled={isSubmitDisabled()} type="submit">
            {loginState === LoginState.Email ? "Request OTP" : "Log in"}
          </Button>
        )}
      </div>
    </Card>
  );
};

const Circle = styled.div`
  height: 75px;
  width: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  border-radius: 40px;
  border: 1px solid #eee;
`;

const P = styled.p`
  font-size: 12px;
  font-weight: 300;
  color: ${WEAK_FONT_COLOR};
`;

type ButtonProps = {
  isDisabled: boolean;
};
const Button = styled.button<ButtonProps>`
  background-color: ${props => (props.isDisabled ? "#eee" : PRIMARY_BUTTON_COLOR)};
  border: none;
  font-weight: 500;
  color: ${SNOW};
  height: 40px;
  min-width: 100px;
  border-radius: 4px;
  margin: 20px;
  margin-top: 30px;
  font-size: 12px;
  pointer-events: ${props => (props.isDisabled ? "none" : "all")};

  transition: all 0.3s;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;

const InputField = styled.input`
  height: 22px;
  padding: 8px;
  width: 50%;
  border-radius: 4px;
  border: 1px solid #eee;
  outline: none;
  margin: 10px;
  margin-top: 0px;
  font-size: 14px;
  background-color: ${props => (props.disabled ? "#F9F9F9F9" : SNOW)};
`;

const Card = styled.form`
  width: 400px;
  height: 450.410196625px;
  background-color: ${SNOW};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default App;
